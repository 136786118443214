import React from "react";
import './index.scss'

const NotFoundPage = () => (
  <div className="home"> 
    <pre>
{`               |))    |))
 .             |  )) /   ))
 \\\\   ^ ^      |    /      ))
  \\\\(((  )))   |   /        ))
   / G    )))  |  /        ))
  |o  _)   ))) | /       )))
   --' |     ))\`/      )))
    ___|              )))
   / __\\             ))))\`()))
  /\\@   /             \`(())))
  \\/   /  /\`_______/\\   \\  ))))
       | |           \\ \\ |  )))
       | | Not Found | | |   )))
       |_@    404    |_|_@    ))
      /_/           /_/_/
`}</pre>
  </div>
)

export default NotFoundPage
